import React from "react";

import i1 from "assets/icons/wedding-ring-1.svg";
import i2 from "assets/icons/anniv.svg";
import i3 from "assets/icons/baby.svg";
import i4 from "assets/icons/entreprise.svg";

const Section1 = () => {
  return (
    <section className="max-screen pb-20 -mt-20 xl:-mt-28 relative z-10 ">
      <div className="grid grid-col-1 sm:grid-cols-2 lg:grid-cols-4 justify-center flex-wrap  items-center gap-8">
        {items.map((item, index) => {
          return <SingleBox {...item} key={index} />;
        })}
      </div>
    </section>
  );
};

export default Section1;

const items = [
  {
    icon: i1,
    title: "Mariage",
    desc: "Capturez l'amour et la joie de votre grand jour",
  },
  {
    icon: i2,
    title: "Anniversaire",
    desc: "Des souvenirs photo fun et mémorables",
  },
  {
    icon: i3,
    title: "Baptême",
    desc: "Chaque instant sacré, capturé",
  },
  {
    icon: i4,
    title: "Entreprise",
    desc: "Votre marque en images mémorables",
  },
];

const SingleBox = ({ icon, title, desc }) => {
  return (
    <article
      className="bg-white dark:bg-[#111111] rounded-[40px] rounded-tr-none px-8 py-16
      flex-c flex-col gap-5 shadow-[0px_3px_40px_#00000015] text-center flex-1 h-full justify-between">
      <div className="bg-primary/25 rounded-[20px] rounded-tr-none p-4">
        <img src={icon} alt={title} />
      </div>
      <h3 className="text-2xl text-black dark:text-white opacity-70">
        {title}
      </h3>
      <p className="text-lg text-black dark:text-white opacity-70">{desc}</p>
    </article>
  );
};
