"use client";

import logo from "assets/logo-IE.png";
import calendar from "assets/icons/calendar.svg";
import { BiMenuAltRight, BiSolidMoon, BiX } from "react-icons/bi";
import { BsCalendar3, BsFillSunFill } from "react-icons/bs";

import { useEffect, useState } from "react";
import useThemePreference from "hooks/useThemePreference";
const Navbar = () => {
  const { theme, toggleTheme } = useThemePreference();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [showMenu]);

  const scrollToContact = () => {
    const contactElement = document.getElementById("contact");

    if (contactElement) {
      const yOffset =
          contactElement.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };
  return (
    <nav
      className={`absolute top-0 left-1/2 -translate-x-1/2 w-full  z-10 
          flex items-center  `}>
      <div className=" max-screen  flex justify-between items-center gap-8 w-full relative z-10 text-white py-6 lg:py-12">
        <ul className="flex items-center gap-8 2xl:gap-20  max-lg:hidden">
          {links.slice(0, 3).map((item, index) => {
            return (
              <li key={index}>
                <a
                  href={item.id}
                  className="hover:text-primary td-300 3xl:text-xl">
                  {item.name}
                </a>
              </li>
            );
          })}
        </ul>
        <div className="">
          <a href={"/"}>
            <img
              src={logo}
              width={289}
              height={80}
              alt=""
              className="h-auto w-24 sm:w-32 xl:w-48 3xl:w-60"
            />
          </a>
        </div>
        <div className="flex items-center gap-8 2xl:gap-12">
          <ul className="flex items-center gap-8 2xl:gap-20  w-full max-lg:hidden">
            {links.slice(3, 6).map((item, index) => {
              return (
                <li key={index}>
                  <a
                    href={item.id}
                    className="hover:text-primary td-300 3xl:text-xl">
                    {item.name}
                  </a>
                </li>
              );
            })}
          </ul>{" "}
          <div className="flex items-center gap-4 ">
            <button
              onClick={toggleTheme}
              className="text-gray-400 dark:text-primary text-2xl sm:text-3xl">
              {theme === "dark" ? <BsFillSunFill /> : <BiSolidMoon />}
            </button>

            <button
                onClick={() => scrollToContact()}
                className=" btn btn-main flex items-center justify-center gap-3 max-sm:px-4">
              <span className="text-base md:text-xl lg:text-2xl">
                {" "}
                <BsCalendar3 />
              </span>

              <span className="max-sm:hidden"> Réservation</span>
            </button>
            <button
              className="lg:hidden text-4xl sm:text-5xl text-gray-400 hover:text-primary outline-none"
              onClick={() => setShowMenu((prev) => !prev)}>
              {!showMenu ? <BiMenuAltRight /> : <BiX />}
            </button>
          </div>
        </div>
      </div>
      <MobileMenu setShowMenu={setShowMenu} showMenu={showMenu} />
    </nav>
  );
};

export default Navbar;

const MobileMenu = ({ showMenu }) => {
  return (
    <aside
      className={`fixed w-full h-screen  top-0 left-0 bg-[#696969] text-white dark:bg-[#111111]
     z-40 menu-animation  ${
       !showMenu
         ? "-translate-x-full  mt-32 sm:mt-40 "
         : "translate-x-0 mt-32 sm:mt-40 "
     }`}>
      <div className="flex flex-col gap-12 mt-12">
        <ul className="flex flex-col items-center gap-4 text-xl font-medium">
          {links.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.id} className="hover:text-primary td-300">
                  {item.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </aside>
  );
};

const links = [
  { name: "Le Concept", id: "#evenements-prives" },
  { name: "Formules", id: "#formules" },
  { name: "Notre travail", id: "#travail" },
  { name: "Avis", id: "#avis" },
  { name: "Contact", id: "#contact" },
  { name: "FAQ", id: "#FAQ" },
];
