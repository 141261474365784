import React, { useState } from "react";
import i1 from "assets/icons/logofitnesspark.png";
import i2 from "assets/icons/logo231east.png";
import i3 from "assets/icons/flamslogo.png";
import i4 from "assets/icons/ESGLogo.png";
import i5 from "assets/icons/smartclinic.png";
import i6 from "assets/icons/chambrelogo.png";
import i1l from "assets/icons/company-1.png";
import { useTheme } from "context/themeContext";

const Companies = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const { globalTheme } = useTheme();

  const items = [globalTheme === "dark" ? i1l : i1, i2, i3, i4, i5, i6];

  return (
    <section className="max-screen flex flex-col gap-12 overflow-hidden py-20">
      <div className="flex flex-col gap-8">
        {" "}
        <div className="flex-c flex-col gap-20">
          <h2 className=" text-3xl lg:text-4xl uppercase title-bar text-center">
            Ils nous ont fait <span className="text-primary">confiance</span>
          </h2>
          <p className="2xl:text-lg 3xl:text-xl xl:w-[80%] 2xl:w-[80%] text-gray-dark">
            Nous sommes fiers d'avoir collaboré avec des marques et institutions
            renommées de <span className="text-gray-light">Strasbourg</span> et
            du <span className="text-gray-light">Grand-Est</span> qui ont choisi{" "}
            <span className="text-primary">Instant Event</span> pour animer
            leurs <span className="text-gray-light">événements</span>.
          </p>
        </div>
      </div>
      <div className="bg-white dark:bg-[#111111] shadow-[0px_3px_40px_#0000001A]  rounded-[40px] py-4 max-w-[98%] sm:max-w-[90%] mx-auto">
        <div className="flex flex-col items-center gap-8   overflow-hidden ">
          <div
            className="overflow-hidden flex gap-8  relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div
              className={`logos-slide inline-flex gap-8 bg-transparent ${
                isHovered ? "paused" : ""
              }`}>
              {items.map((item, index) => {
                return (
                  <div key={index} className="flex-c w-24 lg:w-40">
                    <img
                      src={item}
                      className={`td-300 ${index !== 0 && "dark:invert"} `}
                      alt="logo"
                    />
                  </div>
                );
              })}
            </div>
            <div
              className={`logos-slide inline-flex gap-8 bg-transparent ${
                isHovered ? "paused" : ""
              }`}>
              {items.map((item, index) => {
                return (
                  <div key={index} className="flex-c w-24 lg:w-40">
                    <img
                      src={item}
                      className={`td-300 ${index !== 0 && "dark:invert"} `}
                      alt="logo"
                    />
                  </div>
                );
              })}
            </div>
            <div
              className={`logos-slide inline-flex gap-8 bg-transparent ${
                isHovered ? "paused" : ""
              }`}>
              {items.map((item, index) => {
                return (
                  <div key={index} className="flex-c w-24 lg:w-40">
                    <img
                      src={item}
                      className={`td-300 ${index !== 0 && "dark:invert"} `}
                      alt="logo"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Companies;
