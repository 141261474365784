import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import emailjs from "@emailjs/browser";
import { useEffect } from "react";
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: "12px 4px",
    border: "1px solid #f6a700",
    outline: "none",
    boxShadow: "none",
    borderRadius: "10px",
    "&:hover, &:focus": {
      border: "1px solid #f6a700",
    },
    backgroundColor: "transparent",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#f6a700" : "#f6a700",
  }),

  menu: (provided, state) => ({
    ...provided,
    color: "black",
    // backgroundColor: state.isActive ? "#fff" : "#fff",
  }),
};
const initialValues = {
  name: "",
  company: "",
  email: "",
  tel: "",
  event: "mariage",
  invite: "",
  date: "",
  formule: "prestige",
  joindre: "Téléphone",
  social: "Instagram",
};
const Contact = () => {
  const [values, setValues] = useState({
    name: "",
    company: "",
    email: "",
    tel: "",
    event: "mariage",
    invite: "",
    date: "",
    formule: "prestige",
    joindre: "Téléphone",
    social: "Instagram",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleSelectChange = (selectedOption, { name }) => {
    setValues({ ...values, [name]: selectedOption.value });
  };
  const [isSuccess, setIsSuccess] = useState(false);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
        .send(
            "service_7cmdprb",
            "template_hogqc2l",
            { ...values, date: values.date.toLocaleDateString("fr-FR", options) },
            "MeYQexzVtQMQjsSwZ"
        )
        .then(
            (result) => {
              console.log(result.text);
              setValues(initialValues);
              setIsSuccess(true);
            },
            (error) => {
              console.log(error.text);
            }
        );
    // console.log({
    //   ...values,
    //   date: values.date.toLocaleDateString("fr-FR", options),
    // });
  };
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 1000);
    }
  }, [isSuccess]);
  return (
      <section
          id="contact"
          className="max-screen flex flex-col items-center  gap-y-12   overflow-hidden pt-20">
        <div className="flex-c flex-col gap-20">
          <h2 className=" text-3xl lg:text-4xl uppercase title-bar text-center">
            Prêt pour <span className="text-primary">l'inoubliable ?</span>
          </h2>
          <p className="2xl:text-lg 3xl:text-xl  text-gray-dark">
            Chaque<span className="text-gray-light"> évènement</span> est{" "}
            <span className="text-primary">unique</span>. <br />
            Remplissez le <span className="text-gray-light">formulaire</span>{" "}
            ci-dessous pour commencer votre{" "}
            <span className="text-gray-light">aventure</span> avec{" "}
            <span className="text-primary">Instant Event</span>.
            <br />
            Que ce soit pour un <span className="text-gray-light">mariage</span>,
            une <span className="text-gray-light">fête d'entreprise</span> ou tout
            autre <span className="text-gray-light">événement</span>, nous sommes
            prêts à rendre votre <span className="text-gray-light">journée</span>{" "}
            <span className="text-primary">exceptionnelle</span>.
          </p>
        </div>
        <div className="w-full lg:px-12 ">
          <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-4 md:gap-8 relative rounded-[40px] bg-white dark:bg-[#111111]
          shadow-[0px_3px_40px_#0000001A] px-6 py-12 lg:p-20 ">
            <div className="flex flex-wrap gap-3 md:gap-4 xl:gap-12 ">
              <div className="flex-1">
                <InputFormLabel
                    label={"Nom"}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    placeholder={"Votre nom"}
                />
              </div>

              <div className="w-full md:flex-1">
                <InputFormLabel
                    label={"Prénom"}
                    name="prenom"
                    value={values.prenom}
                    onChange={handleChange}
                    placeholder={"Votre prénom"}
                />
              </div>
            </div>
            <div className="flex flex-wrap gap-3 md:gap-4 xl:gap-12 ">
              <div className="flex-1">
                <InputFormLabel
                    label={"Adresse mail"}
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    placeholder={"Votre Adresse mail"}
                />
              </div>

              <div className="w-full md:flex-1">
                <InputFormLabel
                    label={"Numéro de téléphone"}
                    name="tel"
                    type="tel"
                    value={values.tel}
                    onChange={handleChange}
                    placeholder={"+33 0 00 00 00 00"}
                />
              </div>
            </div>
            <div className="flex flex-wrap flex-col xl:flex-row gap-3 md:gap-4 xl:gap-12 ">
              <div className="flex-1 flex items-center flex-col sm:flex-row w-full gap-4">
                <div className="flex flex-col gap-2   w-full text-base xl:text-base ">
                  <label className=" font-medium text-base xl:text-base ">
                    Date de l'événement
                  </label>
                  <ReactDatePicker
                      selected={values.date}
                      onChange={(item) => setValues({ ...values, date: item })}
                      className="td-300 w-full border text-black dark:text-white border-primary bg-transparent rounded-[10px] py-4 px-2
                placeholder:opacity-50   focus:outline-none"
                      placeholderText="jj/mm/aaaa"
                  />
                </div>

                {/* <InputFormLabel
                label={"Date de l'événement"}
                name="date"
                type="date"
                value={values.date}
                onChange={handleChange}
                placeholder={"jj/mm/aaaa"}
              /> */}
                <SelectFormLabel
                    label={"Type d'événement"}
                    name="event"
                    type="email"
                    value={values.event}
                    onChange={handleSelectChange}
                    placeholder={"Votre Type d'événement"}
                    options={[
                      { value: "Mariage", label: "Mariage" },
                      { value: "Anniversaire", label: "Anniversaire" },
                      { value: "Baptême", label: "Baptême" },
                      { value: "Évènement Pro", label: "Évènement Pro" },
                      { value: "Autre", label: "Autre" },
                    ]}
                />
              </div>

              <div className="flex-1">
                <InputFormLabel
                    label={"Nombre d'invités"}
                    name="invite"
                    type="tel"
                    value={values.invite}
                    onChange={handleChange}
                    placeholder={"Il y auras ... "}
                />
              </div>
            </div>
            <div className="flex flex-wrap flex-col xl:flex-row gap-3 md:gap-4 xl:gap-12 ">
              <div className="flex-1 flex flex-col sm:flex-row items-center w-full gap-4">
                <SelectFormLabel
                    label={"Formule souhaitée"}
                    name="formule"
                    value={values.formule}
                    onChange={handleSelectChange}
                    options={[
                      { value: "Éco", label: "Éco" },
                      { value: "Smart", label: "Smart" },
                      { value: "Gold", label: "Gold" },
                      { value: "Prestige", label: "Prestige" },
                      { value: "Devis personnalisé", label: "Devis personnalisé" },
                    ]}
                />
                <SelectFormLabel
                    label={"Comment vous joindre ?"}
                    name="joindre"
                    value={values.joindre}
                    onChange={handleSelectChange}
                    options={[
                      { value: "Téléphone", label: "Téléphone" },
                      { value: "E-mail", label: "E-mail" },
                    ]}
                />
              </div>

              <div className="flex-1">
                <SelectFormLabel
                    label={"Comment nous avez vous connu ?"}
                    name="social"
                    value={values.social}
                    onChange={handleSelectChange}
                    options={[
                      { value: "Instagram", label: "Instagram" },
                      { value: "Google", label: "Google" },
                      { value: "Lors d'un évènement", label: "Lors d'un évènement" },
                      { value: "Autre", label: "Autre" },
                    ]}
                />
              </div>
            </div>
            <div className="flex-c relative">
              <button role="submit" className="btn btn-second ">
                Envoyer la demande
              </button>
              {isSuccess && (
                  <span className="text-green-500 text-sm font-semibold text-center absolute -bottom-8">
                Merci!
              </span>
              )}
            </div>
          </form>
        </div>
        <div>
          <p className="2xl:text-lg 3xl:text-xl text-center text-gray-dark ">
            Nous nous engageons à répondre à toutes les demandes dans la{" "}
            <span className="text-gray-light">journée</span>. <br /> Votre{" "}
            <span className="text-gray-light">événement</span> mérite le{" "}
            <span className="text-primary">meilleur</span>, et nous sommes là pour
            le <span className="text-gray-light">réaliser</span>.
          </p>
        </div>
      </section>
  );
};

export default Contact;
const InputFormLabel = ({
                          type = "text",
                          name,
                          value,
                          onChange,
                          isDisabled,
                          label,
                          title,
                          placeholder,
                          half,
                          onFocus,
                          onBlur,
                          onClick,
                          required = true,
                        }) => {
  return (
      <div className={`flex flex-col gap-2   w-full text-base `}>
        <label htmlFor={name} className=" font-medium text-base">
          {label || name}
          {/* {required && <span className="text-white">*</span>} */}
        </label>
        <input
            id={name}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            disabled={isDisabled}
            className="td-300 w-full border text-black dark:text-white border-primary bg-transparent rounded-[10px] py-4 px-2
              placeholder:opacity-50   focus:outline-none "
            title={title}
            placeholder={placeholder}
            onFocus={onFocus}
            onBlur={onBlur}
            onClick={onClick}
            required={required}
        />
      </div>
  );
};

const SelectFormLabel = ({
                           name,
                           value,
                           onChange,

                           label,

                           options,

                           required = true,
                         }) => {
  return (
      <div
          className={`flex flex-col gap-2 w-full text-black dark:text-white custom-select text-base `}>
        <label htmlFor={name} className="font-medium text-base ">
          {label || name}
        </label>
        <Select
            name={name}
            styles={customStyles}
            isSearchable={false}
            options={options}
            value={options.find((option) => option.value === value)}
            onChange={onChange}
        />
      </div>
  );
};
