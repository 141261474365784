import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  BsArrowLeftCircle,
  BsArrowRightCircle,
  BsFillPlayCircleFill,
} from "react-icons/bs";
import ReactPlayer from "react-player";
const Section4 = () => {
  return (
      <section
          id="travail"
          className="max-screen flex flex-col items-center  gap-y-12   overflow-hidden py-20">
        <div className="flex-c flex-col gap-20">
          <h2 className=" text-3xl lg:text-4xl uppercase title-bar text-center">
            Moments <span className="text-primary">capturés</span>
          </h2>
          <p className="2xl:text-lg 3xl:text-xl xl:w-[80%] 2xl:w-[80%] text-gray-dark">
            Découvrez quelques-uns des moments{" "}
            <span className="text-gray-light">mémorables</span> que nous avons
            aidé à immortaliser à{" "}
            <span className="text-gray-light">Strasbourg</span> et dans la région{" "}
            <span className="text-gray-light">Alsace</span>. Chaque{" "}
            <span className="text-gray-light">événement</span> est{" "}
            <span className="text-primary">unique</span>, et nous sommes fiers de
            contribuer à rendre ces souvenirs inoubliables.
          </p>
        </div>
        <div className="max-w-[min(700px,95vw)] lg:max-w-[500px] 2xl:max-w-[600px]  relative">
          <Swiper
              slidesPerView={1}
              spaceBetween={24}
              pagination={{
                clickable: true,
              }}
              loop={true}
              autoplay={false}
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
                touch: { enabled: true },
              }}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              className="testo-slide  ">
            {items.map((item, index) => {
              return (
                  <SwiperSlide key={index} className="">
                    <SingleSlide item={item} />
                  </SwiperSlide>
              );
            })}
          </Swiper>
          <button className="prev absolute text-primary outline-none max-md:left-0 max-md:text-5xl -left-14 top-1/2 z-10 -translate-y-1/2 text-2xl disabled:!opacity-50 md:text-5xl">
            {" "}
            <BsArrowLeftCircle />
          </button>
          <button className="next absolute text-primary  outline-none max-md:right-0 max-md:text-5xl   -right-14 top-1/2 z-10 -translate-y-1/2 text-2xl disabled:!opacity-50 md:text-5xl">
            {" "}
            <BsArrowRightCircle />
          </button>
        </div>
      </section>
  );
};

export default Section4;
const SingleSlide = ({ item }) => {
  return (
      <div
          className="card-shadow  shadow-[0px_3px_40px_#0000001A]
     rounded-lg  flex-c overflow-hidden">
        <div className="text-6xl">
          <iframe
              width="333"
              height="589"
              src={item}
              title="📸 Fitness Park nous a fait confiance pour l’animation de leur 7ème anniversaire💫"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
        </div>
      </div>
  );
};

const items = [
  "https://www.youtube.com/embed/nd5PA-axQN8",
  "https://youtube.com/embed/HdvExm5TpIQ",
  "https://youtube.com/embed/qgp8dLXKVJM",
  "https://youtube.com/embed/lDEk-PO2_Jg",
];
