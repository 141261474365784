import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { AiOutlineDownCircle } from "react-icons/ai";
import wave from "assets/footer.png";
import wave2 from "assets/footer-2.png";

import line from "assets/line.svg";
import { RiMailAddFill } from "react-icons/ri";
import { useTheme } from "context/themeContext";
const FAQ = () => {
  const { globalTheme } = useTheme();
  const img = globalTheme === "dark" ? wave : wave2;

  const scrollToContact = () => {
    const contactElement = document.getElementById("contact");

    if (contactElement) {
      const yOffset =
          contactElement.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  return (
    <section id="FAQ" className="py-20 relative z-0">
      <div className="max-screen xl:px-10 2xl:px-20 flex flex-col gap-8 2xl:gap-12">
        <div className="flex-c flex-col gap-12 ">
          <h2 className=" text-3xl lg:text-4xl uppercase  text-center relative ">
            FAQ
            <span className="absolute -bottom-8 left-1/2 -translate-x-1/2 w-[300px]">
              <img src={line} alt="line" />
            </span>
          </h2>
          <p className="text-gray-dark 2xl:text-2xl">
            Voici quelques-unes des interrogations les plus courantes
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-6">
          {faqs?.map((item, index) => {
            return (
              <div key={index} data-aos="fade-up" data-aos-duration="1500">
                <SingleFAQ {...item} />
              </div>
            );
          })}
          <div className="flex flex-col items-center gap-4">
            <p className="text-gray-dark text-lg 3xl:text-2xl text-center">
              Aucune de ces questions ne correspond à votre demande ?
            </p>
            <div>
              <button
                  onClick={() => scrollToContact()}
                  className=" btn btn-second flex items-center justify-center gap-2 max-sm:px-4 ">
                <span className="text-base md:text-xl lg:text-2xl">
                  {" "}
                  <RiMailAddFill />
                </span>
                <span> Contactez-nous</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <img
        src={img}
        alt=""
        className="w-full absolute   bottom-2 translate-y-full drop-shadow-lg max-h-[180px] "
      />
    </section>
  );
};

export default FAQ;
const SingleFAQ = ({ question, answer }) => {
  return (
    <Accordion className="  gap-4  !rounded-3xl lg:!rounded-full py-4   px-0 md:px-6 !divide-y-0 bg-[#FEFFFF] dark:bg-[#111111] shadow-[0px_3px_40px_#0000001A] text-black dark:text-white">
      <AccordionSummary
        className="  !rounded-3xl lg:!rounded-full "
        expandIcon={
          <span className="text-primary text-3xl">
            <AiOutlineDownCircle />
          </span>
        }
        aria-controls={question}
        id={question}>
        <p className="font-semibold text-base 3xl:text-xl "> {question}</p>
      </AccordionSummary>
      <AccordionDetails>
        <p className="text-sm md:text-base ">{answer}</p>
      </AccordionDetails>
    </Accordion>
  );
};
const faqs = [
  {
    question: "Comment réserver ?",
    answer: "  Vous pouvez utiliser le formulaire ci-dessus ou nous contacter directement par téléphone. La réservation de date s'effectue après réception de l'acompte (30%). Le solde doit être réglé au plus tard 7 jours avant l'événement. Nous nous engageons à répondre à toutes les demandes dans la journée. ",
  },
  { question: "Dans quels secteurs livrez-vous ?", answer: "Nous sommes basés à Strasbourg et livrons dans tout le Grand-Est." },
  { question: "Puis-je payer en plusieurs fois ?", answer: "Absolument ! Vous pouvez étaler le paiement jusqu'à 6 fois. Cependant, le montant total doit être réglé avant l'événement." },
  { question: "Puis-je personnaliser le cadre photo ?", answer: "Absolument ! Les cadres sont entièrement personnalisables. Nous travaillons avec vous pour s'assurer qu'il correspond parfaitement à votre événement." },
  {
    question: "Qui s'occupe de l'installation et de la désinstallation ?",
    answer: "Notre équipe se charge de tout. En 45 minutes, tout est prêt, et nous revenons ensuite pour la désinstallation.",
  },
  {
    question:
      "Que se passe-t-il si j'utilise tous les tirages ou si je n'en utilise pas tous ?",
    answer: "Une fois le nombre de tirages atteint, l'impression s'arrête, mais les photos continuent d'être envoyées électroniquement. Les tirages non utilisés ne sont pas remboursables",
  },
  { question: "Combien de temps dure la location ?", answer: "Entre 4 et 6 heures, selon la formule choisie." },
  {
    question: "Proposez-vous une formule avec tirages illimités ?",
    answer: "Oui, nous pouvons proposer une telle formule sur devis.",
  },
  {
    question: "Est-ce qu'il y a quelqu'un qui reste sur place ?",
    answer: "La prestation de base ne comprend pas d'animateur, mais nous pouvons ajouter ce service sur devis.",
  },
];
