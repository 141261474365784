import React from "react";
import { BsCalendar3, BsCheck2Circle } from "react-icons/bs";
import { RiMailAddFill } from "react-icons/ri";
import stars from "assets/icons/star.svg";

import delivery from "assets/icons/delivery.svg";

import { useTheme } from "context/themeContext";
import rectangle from "assets/Rectangle 9.svg";

import rectangleD from "assets/right-rectange-dark.svg";
const Pricing = () => {
  const { globalTheme } = useTheme();
  const imgSource1 = globalTheme === "dark" ? rectangleD : rectangle;

  const scrollToContact = () => {
    const contactElement = document.getElementById("contact");

    if (contactElement) {
      const yOffset =
          contactElement.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  return (
    <section id="formules" className="py-20 relative z-0">
      <img
        src={imgSource1}
        alt=""
        className="h-[500px] absolute top-[10%] right-0 translate-x-1/2 -z-10"
      />
      <div className="max-screen xl:px-10 2xl:px-20 flex flex-col gap-12 2xl:gap-12">
        <div className="flex-c flex-col gap-20 mb-12">
          <h2 className=" text-3xl lg:text-4xl uppercase title-bar text-center">
            Nos <span className="text-primary">formules</span> adaptées à chaque{" "}
            <span className="text-primary">occasion</span>
          </h2>
          <p className="text-gray-dark 2xl:text-2xl">
            Un rapport qualité/prix imbattable
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-4 gap-8 justify-center gap-y-24">
          {pricing.map((item, index) => {
            return (
              <div key={index} className="flex flex-col gap-8 items-center">
                <article
                  className="bg-white dark:bg-[#111111] relative rounded-2xl p-8
                 pt-16 flex flex-col justify-between gap-4 shadow-[0px_3px_40px_#0000001A] ">
                  <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-primary px-12 py-4">
                    <h2 className="text-3xl 2xl:text-4xl  text-center uppercase">
                      {item.title}
                    </h2>
                  </div>
                  {item.title === "prestige" && (
                    <div className="absolute top-0 right-0  -translate-y-1/2  ">
                      <img src={stars} alt="" />
                    </div>
                  )}
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col items-center gap-2">
                      <span className="text-primary text-5xl 2xl:text-6xl font-bold">
                        {item.tirage}
                      </span>
                      <span className="text-xl 2xl:text-2xl text-gray-1">
                        Tirages
                      </span>
                    </div>
                    <ul className=" flex flex-col">
                      {item.benefits.map((item, index) => {
                        return (
                          <li
                            className="py-2 text-gray-1 xl:text-lg 2xl:text-xl flex items-center gap-2"
                            key={index}>
                            <span className="text-primary text-2xl">
                              <BsCheck2Circle />
                            </span>
                            <div className="text-gray-light [&_span]:font-semibold">
                              {item}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="flex-c">
                    <div className="bg-primary text-black dark:bg-[#342B19] btn dark:text-white text-2xl 2xl:text-3xl font-semibold">
                      {item.price} €
                    </div>
                  </div>
                </article>
                <div>
                  <button
                      onClick={() => scrollToContact()}
                      className=" btn btn-second flex items-center justify-center gap-2 max-sm:px-4 ">
                    <span className="text-base md:text-xl lg:text-2xl">
                      {" "}
                      <BsCalendar3 />
                    </span>
                    <span> Réservation</span>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-center items-center gap-4">
          <img src={delivery} alt="" />
          <div>
            <p className="font-medium text-3xl 2xl:text-4xl">
              Livraison Offerte
            </p>
            <p className="font-bold text-lg 2xl:text-xl">
              Dans Strasbourg et <span className="text-primary">+</span> 20 km
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <p className="text-xl 2xl:text-2xl 3xl:text-3xl text-center text-black dark:text-white font-bold">
            Besoin de plus de
            <span className="relative inline-block mx-2">
              {" "}
              bonheur{" "}
              <span className="absolute -bottom-0 right-0 h-3 sm:h-4 bg-primary w-full left-1/2 -translate-x-1/2 -z-10"></span>
            </span>
            pour votre événement ?
          </p>
          <p className="2xl:text-lg 3xl:text-xl text-center text-gray-dark">
            Formule tirage <span className="text-gray-light">illimité</span> ?
            Formule avec <span className="text-gray-light">animateur</span>...{" "}
            <span className="text-primary">Instant Event</span> s'adapte à vos
            besoins.
          </p>
          <div className="flex-c">
            <button
                onClick={() => scrollToContact()}
                className=" btn btn-second flex items-center justify-center gap-2 max-sm:px-4 ">
              {" "}
              <span className="text-base md:text-xl lg:text-2xl">
                {" "}
                <RiMailAddFill />
              </span>
              <span> Devis Personnalisé</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;

const pricing = [
  {
    title: "éco",
    price: 180,
    tirage: 100,
    benefits: [
      <p>
        Personnalisation du <span>fond d'écran </span>et du{" "}
        <span>cadre photo</span>
      </p>,
      <p>
        <span>Installation </span>et <span>désinstallation</span>
      </p>,
      <p>
        Tirage numérique <span>illimité</span>
      </p>,
      <p>
        Réception d'un <span>album numérique </span>après l'événement
      </p>,
      <p>
        Garantie des plus <span>beaux sourires </span>de vos{" "}
        <span>invités</span>
      </p>,
    ],
  },
  {
    title: "smart",
    price: 290,
    tirage: 200,
    benefits: [
      <p>
        Personnalisation du <span>fond d'écran </span>et du{" "}
        <span>cadre photo</span>
      </p>,
      <p>
        <span>Installation </span>et <span>désinstallation</span>
      </p>,
      <p>
        Tirage numérique <span>illimité</span>
      </p>,
      <p>
        Réception d'un <span>album numérique </span>après l'événement
      </p>,
      <p>
        Garantie des plus <span>beaux sourires </span>de vos{" "}
        <span>invités</span>
      </p>,
    ],
  },
  {
    title: "gold",
    price: 330,
    tirage: 300,
    benefits: [
      <p>
        Personnalisation du <span>fond d'écran </span>et du{" "}
        <span>cadre photo</span>
      </p>,
      <p>
        <span>Installation </span>et <span>désinstallation</span>
      </p>,
      <p>
        Tirage numérique <span>illimité</span>
      </p>,
      <p>
        Réception d'un <span>album numérique </span>après l'événement
      </p>,
      <p>
        Garantie des plus <span>beaux sourires </span>de vos{" "}
        <span>invités</span>
      </p>,
    ],
  },
  {
    title: "prestige",
    price: 360,
    tirage: 400,
    benefits: [
      <p>
        Personnalisation du <span>fond d'écran </span>et du{" "}
        <span>cadre photo</span>
      </p>,
      <p>
        <span>Installation </span>et <span>désinstallation</span>
      </p>,
      <p>
        Tirage numérique <span>illimité</span>
      </p>,
      <p>
        Réception d'un <span>album numérique </span>après l'événement
      </p>,
      <p>
        Garantie des plus <span>beaux sourires </span>de vos{" "}
        <span>invités</span>
      </p>,
    ],
  },
];
