import Navbar from "components/Navbar";
import "./globals.css";

import Hero from "sections/Hero";
import Section1 from "sections/Section1";
import Section2 from "sections/Section2";
import Section3 from "sections/Section3";
import Pricing from "sections/Pricing";
import Companies from "sections/Companies";
import Section4 from "sections/Section4";
import Reviews from "sections/Reviews";
import Contact from "sections/Contact";
import FAQ from "sections/FAQ";
import Footer from "components/Footer";
import rectangle from "assets/Rectangle 9.svg";
import rectangle2 from "assets/Rectangle 12.svg";
import rectangleD from "assets/right-rectange-dark.svg";
import rectangleD2 from "assets/left-rectange-dark.svg";
import { useTheme } from "context/themeContext";
import ReactGA from 'react-ga';


function App() {
  const { globalTheme } = useTheme();
  const imgSource1 = globalTheme === "dark" ? rectangleD : rectangle;
  const imgSource2 = globalTheme === "dark" ? rectangleD2 : rectangle2;

  return (
    <div className="bg-white dark:bg-[#070707] overflow-x-hidden">
      <Navbar />
      <Hero />
      <Section1 />
      <div className="relative z-0">
        <Section2 />
        <img
          src={imgSource1}
          alt=""
          className="h-[500px] absolute top-[10%] right-0 translate-x-1/2 -z-10"
        />
        <img
          src={imgSource2}
          alt=""
          className="h-[500px] absolute -bottom-[0] left-0 -translate-x-1/2 -z-10"
        />
      </div>

      <Section3 />
      <div className="relative z-0">
        <Pricing />{" "}
        <img
          src={imgSource1}
          alt=""
          className="h-[500px] absolute top-[10%] right-0 translate-x-1/2 -z-10"
        />
      </div>
      <div className="relative z-0">
        <img
          src={imgSource2}
          alt=""
          className="h-[500px] absolute -bottom-[0] left-0 -translate-x-1/2 -z-10"
        />
        <Companies />
      </div>
      <Section4 />
      <div className="relative z-0">
        <img
          src={imgSource1}
          alt=""
          className="h-[500px] absolute top-[10%] right-0 translate-x-1/2 -z-10"
        />
        <Contact />
      </div>

      <div className="relative z-0">
        <Reviews />{" "}
        <img
          src={imgSource2}
          alt=""
          className="h-[500px] absolute -bottom-[0] left-0 -translate-x-1/2 -z-10"
        />
      </div>
      <FAQ />
      <Footer />
    </div>
  );
}

export default App;
