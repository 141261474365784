import { useTheme } from "context/themeContext";
import { useEffect, useState } from "react";

const useThemePreference = () => {
  const [theme, setTheme] = useState("light");
  const { setGlobalTheme } = useTheme();
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
      document.body.classList.toggle("dark", savedTheme === "dark");
    } else {
      const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
      const newTheme = mediaQueryList.matches ? "dark" : "light";
      setTheme(newTheme);
      localStorage.setItem("theme", mediaQueryList.matches ? "dark" : "light");
      document.body.classList.toggle("dark", newTheme === "dark");
      const handleChange = (e) => {
        setTheme(e.matches ? "dark" : "light");
      };

      mediaQueryList.addEventListener("change", handleChange);

      return () => {
        mediaQueryList.removeEventListener("change", handleChange);
      };
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);

    document.body.classList.toggle("dark", newTheme === "dark");
  };
  useEffect(() => {
    setGlobalTheme(theme);
  }, [theme]);
  return { theme, toggleTheme };
};

export default useThemePreference;
