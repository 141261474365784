import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import "swiper/css";

import p1 from "assets/p1.avif";
import p2 from "assets/p2.avif";
import p3 from "assets/p3.avif";
import { AiFillStar } from "react-icons/ai";

const Reviews = () => {
  return (
    <section id="avis" className="py-20 2xl:py-32">
      <div className="max-screen xl:px-10 2xl:px-20 flex flex-col gap-16 2xl:gap-20">
        <div className="flex-c flex-col gap-20">
          <h2 className=" text-3xl lg:text-4xl uppercase title-bar text-center">
            C'est <span className="text-primary">vous</span> qui le dîtes
          </h2>
          <p className="2xl:text-lg 3xl:text-xl xl:w-[80%] 2xl:w-[80%] text-gray-dark">
            Nous sommes fiers d'avoir collaboré avec des marques et institutions
            renommées de <span className="text-gray-light">Strasbourg</span> et
            du <span className="text-gray-light">Grand-Est</span> qui ont choisi{" "}
            <span className="text-primary">Instant Event</span> pour animer
            leurs <span className="text-gray-light">événements</span>.
          </p>
        </div>

        <div className="flex flex-col items-center lg:flex-row gap-10 ">
          <div className="flex-1  flex-c">
            <div className="max-w-[min(700px,95vw)] lg:max-w-[500px] 2xl:max-w-[600px] ">
              <Swiper
                slidesPerView={1}
                spaceBetween={24}
                pagination={{
                  clickable: true,
                }}
                loop={true}
                autoplay={{
                  delay: 3000,
                }}
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                className="testo-slide">
                {items.map((item, index) => {
                  return (
                    <SwiperSlide key={index} className="">
                      <SingleSlide {...item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
          <div className="flex-1  flex flex-col gap-4 2xl:gap-6 ">
            <h3 className="text-gray-dark text-3xl lg:text-4xl font-bold ">
              Chaque événement est une{" "}
              <span className="text-primary">histoire</span>.
            </h3>
            <p className="2xl:text-lg 3xl:text-xl xl:w-[80%] 2xl:w-[80%] text-gray-dark">
              Nous sommes <span className="text-primary">honorés</span> de faire
              partie de tant de{" "}
              <span className="text-gray-light">moments mémorables.</span>
            </p>
            <div className="hidden lg:block">
              <a href="https://g.page/r/CR5abIRREZoDEBM/review" className="btn btn-second py-2.5">
                Laisser un avis
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;

const SingleSlide = (item) => {
  return (
    <div className="shadow-[0px_3px_40px_#0000001A] bg-white dark:bg-[#111111] rounded-2xl m-4 py-8 ">
      <blockquote className=" flex flex-row gap-4 md:gap-8 justify-between p-4 lg:p-8 rounded-2xl text-start">
        <div className="flex flex-col items-start justify-between gap-4">
          <div className="flex items-center gap-2 xl:gap-4">
            <div className=" flex flex-col gap-2">
              <h3 className=" font-semibold text-xl 2xl:text-2xl ">
                {item.name}
              </h3>
              <p className="text-primary text-start text-sm 2xl:text-base font-semibold">
                {item.position}
              </p>
            </div>
          </div>

          <div>
            <p className="text-sm xl:text-base  text-start sm:w-[90%]">
              {item.description}
            </p>
          </div>
          <div className="flex items-center gap-1 text-primary">
            {[...Array(item.rating)].map((_, index) => (
              <AiFillStar kernelMatrix={index} />
            ))}
          </div>
        </div>
      </blockquote>
    </div>
  );
};

const items = [
  {
    name: "Julie SCHMIDT",
    position: "",
    rating: 5,
    description:
      "Excellente prestation ! Les photos sont de très bonnes qualités, parfait pour garder des souvenirs de divers événements.\n",
  },
  {
    name: "Mauricia Vavelin",
    position: "",
    rating: 5,
    description:
      "Super événement ! On a passé un super mega bon moment !! Des photos de grandes qualités ! Franchement je recommande les yeux fermés 🤩🤩🤩",
  },
  {
    name: "Noyan Altun Photography",
    position: "",
    rating: 5,
    description:
      "Location d’un photobooth effectuée pour un anniversaire, résultat, entièrement satisfait ! Tout le monde a pris du plaisir devant l’objectif ! Je recommande sans hésiter 🤩",
  },
  {
    name: "Loic Matti",
    position: "",
    rating: 5,
    description:
      "Très satisfait de la prestation de Instant Event intervenu sur le Kampus pour Octobre Rose très apprécié de nos étudiants. Tout s'est passé comme prévu: respect des délais, installation/récupération, envoi des photos. Nous ferons à nouveau appel pour de futurs événements.",
  },
];
