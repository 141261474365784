import React from "react";

import { BsCalendar3 } from "react-icons/bs";

const Section3 = () => {

  const scrollToContact = () => {
    const contactElement = document.getElementById("contact");

    if (contactElement) {
      const yOffset =
          contactElement.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  return (
    <section className="flex flex-col gap-20 relative overflow-hidden z-0">
      <section className="max-screen flex flex-col items-center gap-20   overflow-hidden py-20 lg:mt-0">
        <div className="flex-c">
          <h2 className=" text-3xl lg:text-4xl uppercase title-bar text-center">
            Avantages
          </h2>
        </div>
        <div className="flex flex-col gap-4">
          <p className="2xl:text-lg 3xl:text-xl text-center text-gray-dark">
            Avec notre <span className="text-primary">Photobooth</span>, créez
            des moments de <span className="text-gray-light">complicité</span>,
            renforcez les <span className="text-gray-light">liens</span> entre
            vos <span className="text-gray-light">invités</span> et offrez leur
            une <span className="text-gray-light">expérience</span>
             <span className="text-primary"> mémorable</span>. Chaque photo prise
            est une <span className="text-gray-light">histoire</span>, un{" "}
            <span className="text-gray-light">éclat de rire</span>, une{" "}
            <span className="text-gray-light">émotion</span>
             <span className="text-primary"> partagée</span>. C'est l'occasion de
            se lâcher, de s'amuser et de repartir avec un souvenir tangible d'un
            moment précieux.
          </p>
          <p className="2xl:text-lg 3xl:text-xl text-center text-gray-dark">
            Ne laissez pas ces <span className="text-gray-light">moments</span>{" "}
            <span className="text-primary">précieux</span> s'échapper.
          </p>
          <p className="text-xl 2xl:text-2xl 3xl:text-3xl text-center text-black dark:text-white font-bold">
            Réservez dès maintenant votre
            <span className="relative inline-block mx-2">
              {" "}
              Photobooth{" "}
              <span className="absolute -bottom-0 right-0 h-3 sm:h-4 bg-primary w-full left-1/2 -translate-x-1/2 -z-10"></span>
            </span>
            et faites de votre événement un souvenir
            <span className=" relative inline-block ml-2">
              {" "}
              inoubliable
              <span className="absolute -bottom-0 right-0 h-3 sm:h-4 bg-primary w-full left-1/2 -translate-x-1/2 -z-10"></span>
            </span>
            .
          </p>
          <div className="flex-c mt-6">
            <button
                onClick={() => scrollToContact()}
                className=" btn btn-second flex items-center justify-center gap-2 max-sm:px-4 ">
              <span className="text-base md:text-xl lg:text-2xl">
                {" "}
                <BsCalendar3 />
              </span>
              <span> Réservation</span>
            </button>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Section3;
