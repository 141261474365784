import React from "react";
import bg from "assets/hero.jpg";
import img from "assets/hero-img.png";

const Hero = () => {
  return (
    <section className="relative min-h-screen overflow-hidden z-0 flex-c pb-20">
      <div className="absolute h-full -z-10 opacity-10 w-full">
        <img src={bg} alt="hero" className="object-cover h-full w-full" />
      </div>
      <div className="absolute bg-gradient-to-b from-black  to-50%  to-white h-full dark:to-black w-full -z-20"></div>
      <div
        className="max-screen  flex items-center   flex-col lg:flex-row 
       py-12 pt-48   text-white z-10 relative gap-8 gap-y-20">
        <div className="flex-1  flex flex-col gap-4 text-left ">
          <h1 className="font-normal text-4xl 2xl:text-[44px] 3xl:text-[54px] text-black dark:text-white uppercase leading-normal">
            La location de <span className="text-primary">Photobooth</span> à
            portée de main
          </h1>
          <div className="flex flex-col gap-4 2xl:text-lg 3xl:text-xl text-[#585858]">
            <p>
              L’activité N°1 pour créer des souvenirs inoubliables et des
              moments de joie partagés lors de votre événement.
            </p>
          </div>
        </div>
        <div className="flex-1 flex-c">
          <img src={img} alt="hero-img" className="max-3xl:max-h-[400px]" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
