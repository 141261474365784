import React from "react";
import img1 from "assets/img-1.png";
import img2 from "assets/img-2.png";

const Section2 = () => {
  return (
    <div className={`flex flex-col gap-20 relative overflow-hidden z-0 py-20 `}>
      <section className="max-screen flex flex-col items-center gap-20   overflow-hidden  lg:mt-0">
        <div className="flex-c">
          <h2 id="evenements-prives" className="text-3xl lg:text-4xl uppercase title-bar  w-full">
            Pour vos événements <span className="text-primary">privées</span>
          </h2>
        </div>
        <div className="flex flex-col items-center md:flex-row gap-12">
          <img src={img1} alt="" className="max-3xl:max-h-[400px]" />
          <div className="flex flex-col gap-4">
            <p className="2xl:text-lg 3xl:text-xl xl:w-[80%] 2xl:w-[80%] text-gray-dark">
              Découvrez comment{" "}
              <span className="text-gray-light">transformer</span> vos mariages,
              anniversaires, baptêmes… en moments{" "}
              <span className="text-gray-light">inoubliables</span>.
            </p>
            <p className="2xl:text-lg 3xl:text-xl xl:w-[80%] 2xl:w-[80%] text-gray-dark">
              Avec <span className="text-primary">Instant Event</span>,
              spécialiste de la location de borne photobooth à{" "}
              <span className="text-gray-light">Strasbourg</span>, capturez
              chaque <span className="text-gray-light">sourire</span>, chaque{" "}
              <span className="text-gray-light">rire</span>, chaque{" "}
              <span className="text-gray-light">moment spécial</span>.
            </p>
            <p className="2xl:text-lg 3xl:text-xl xl:w-[80%] 2xl:w-[80%] text-gray-dark">
              Donnez à vos invités une{" "}
              <span className="text-gray-light">expérience</span>{" "}
              <span className="text-primary">unique</span>, un souvenir{" "}
              <span className="text-gray-light">tangible</span> qu'ils garderont{" "}
              <span className="text-gray-light">à vie</span>.
            </p>
          </div>
        </div>
      </section>
      <section className="max-screen flex flex-col items-center gap-20   overflow-hidden  lg:mt-0">
        <div className="flex-c">
          <h2 className=" text-3xl lg:text-4xl uppercase title-bar text-center">
            Pour vos événements{" "}
            <span className="text-primary">professionnels</span>
          </h2>
        </div>
        <div className="flex flex-col items-center md:flex-row-reverse gap-12">
          <img src={img2} alt="" className="max-3xl:max-h-[400px]" />
          <div className="flex flex-col gap-4">
            <p className="2xl:text-lg 3xl:text-xl xl:w-[80%] 2xl:w-[80%] text-gray-dark">
              Faites de chaque événement d'entreprise, fête de Noël,
              inauguration… une{" "}
              <span className="text-gray-light">occasion</span>{" "}
              <span className="text-primary">mémorable</span> .
            </p>
            <p className="2xl:text-lg 3xl:text-xl xl:w-[80%] 2xl:w-[80%] text-gray-dark">
              Renforcez{" "}
              <span className="text-gray-light">l'esprit d'équipe</span>,
              laissez une impression
              <span className="text-gray-light">durable</span> à vos invités en
              alliant <span className="text-gray-light">professionnalisme</span>{" "}
              et <span className="text-gray-light">plaisir</span>.
            </p>
            <p className="2xl:text-lg 3xl:text-xl xl:w-[80%] 2xl:w-[80%] text-gray-dark">
              Un <span className="text-primary">Photobooth</span> est bien plus
              qu'une simple <span className="text-gray-light">animation</span> :
            </p>
            <p className="2xl:text-lg 3xl:text-xl xl:w-[80%] 2xl:w-[80%] text-gray-dark">
              C'est un outil de <span className="text-primary">branding</span>{" "}
              <span className="text-gray-light">puissant</span> et un{" "}
              <span className="text-gray-light">
                générateur de souvenirs positifs
              </span>{" "}
              pour vos collaborateurs, partenaires et clients.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Section2;
