import React from "react";
import logoB from "assets/logo-sm-black.png";
import logoW from "assets/logo-sm-white.png";
import { AiOutlineMail } from "react-icons/ai";
import { FaSnapchatGhost, FaTiktok } from "react-icons/fa";
import { BsInstagram, BsTelephone } from "react-icons/bs";
import { useTheme } from "context/themeContext";

const Footer = () => {
  const { globalTheme } = useTheme();
  const img = globalTheme === "dark" ? logoW : logoB;

  return (
    <footer className="bg-white/70 dark:bg-black pt-28 lg:pt-48   flex-c flex-col gap-8 ">
      <div className="max-screen flex flex-col lg:flex-row gap-y-12 justify-between items-center w-full px-4">
        <div className="flex flex-col gap-4 items-center order-3 lg:order-1">
          <p className="text-lg 3xl:text-2xl">Suivez-nous sur :</p>
          <ul className="flex items-center gap-4">
            {socialLinks.map((item, index) => {
              return (
                <li key={index}>
                  <a
                    className="text-primary hover:text-primary/70 text-3xl td-300"
                    href={item.link}
                    aria-label={item.name}>
                    {item.icon}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex flex-col gap-8 items-center order-1 lg:order-2">
          <img src={img} alt="logo" className="h-auto w-48 xl:w-64" />
          <p className="text-lg 3xl:text-2xl">
            VOTRE PARTENAIRE POUR DES MOMENTS INOUBLIABLES.
          </p>
        </div>
        <div className="flex flex-col gap-4 order-2 lg:order-3">
          <ul className="flex  flex-col gap-4">
            <li>
              <a
                className=" hover:text-primary/90 text-xl td-300 flex items-center gap-2"
                href="tel:076991108">
                <span className="border rounded-full p-2 flex-c border-primary text-primary">
                  <BsTelephone />
                </span>
                <span className="text-lg 3xl:text-2xl"> 07 56 99 11 08</span>
              </a>
            </li>
            <li>
              <a
                className=" hover:text-primary/90 hello@instantevent.fr td-300 flex items-center gap-2"
                href="mailto:hello@instantevent.fr">
                <span className="border rounded-full p-2 flex-c border-primary text-primary">
                  <AiOutlineMail />
                </span>
                <span className=" text-lg 3xl:text-2xl">
                  hello@instantevent.fr
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="  bg-[#FFF4D9] dark:bg-black w-full px-4 py-4 border-t-4 border-primary">
        <span className="font-light">
          ©Instant Event - Tous droits réservés
        </span>
      </div>
    </footer>
  );
};

export default Footer;
const socialLinks = [
  { link: "#", icon: <BsInstagram />, name: "Instagram" },
  { link: "#", icon: <FaTiktok />, name: "TikTok" },
  { link: "#", icon: <FaSnapchatGhost />, name: "Snapchat" },
];
